import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { adminRoutes } from '../../../pages/admin/admin-routing.module';
import { ArtistService } from '../../../services/artist.service';
import { PartnersListComponent } from '../../admin/partners-list/partners-list.component';

@Component({
  selector: 'app-base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss'],
})
export class BaseHeaderComponent implements OnInit, OnDestroy {

  @Input() backButton: boolean;
  @Input() showForLg: boolean;
  @Input() showBackground: boolean;
  @Input() hideForLg = true;
  @Input() title: string;

  joinSubscription: Subscription;
  routerSubscription: Subscription;
  hideArtistName = false;
  artistName$: Observable<string>;

  constructor(
    public artistService: ArtistService,
    public auth: AuthService,
    private modalController: ModalController,
    private router: Router,
    // public routerOutlet: IonRouterOutlet,
  ) { }

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideArtistName = adminRoutes.includes(event.url);
      }
    });
    this.artistName$ = this.artistService.signedInArtist$.pipe(
      map(artist => this.artistService.getName(artist))
    );
  }
  ngOnDestroy(): void {
      this.joinSubscription?.unsubscribe();
      this.routerSubscription?.unsubscribe();
  }

  async showSelectPartnerModal() {
    const modal = await this.modalController.create({
      component: PartnersListComponent,
      // presentingElement: this.routerOutlet.nativeEl,
      canDismiss: true,
      cssClass: 'partners-list'
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      this.artistService.selectPartnerId(data);
      this.router.navigate(['/pages/earnings']);
    }
  }

}
