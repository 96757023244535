import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { ArtistService } from './services/artist.service';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Artist } from '../models/db-models';
import { DbService } from './services/db.service';
import { User } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
declare let docuSignClick: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  artistSubscription: Subscription;
  loggedIn: boolean;
  contractAgreementRequired = true;
  agreementVerified = false;
  agreementUpdateRequired = false;
  showSpinner = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    private db: DbService,
    public artistService: ArtistService,
  ) {
  }

  async ngOnInit() {
    // App auto updater
    CapacitorUpdater.notifyAppReady();

    this.listenToEvents();
  }

  displayContractIfNeeded(artist: Artist, user: User) {
    docuSignClick.Clickwrap.render({
      environment: environment.docusign.environment,
      accountId: environment.docusign.accountId,
      clickwrapId: artist.contract.clickId,
      clientUserId: artist.id,
      documentData: {
        fullName: artist.name,
        email: user.email,
        date: new Date().toLocaleDateString('pt-BR'),
      },

      style: {
        scrollControl: 'fill_parent',
        // scrollControl: 'browser',
        container: {
          backgroundColor: '#e7ebef',
          borderWidth: '1px',
      },
        agreeButton: {
          // boxShadow: '0 2px 2px 2px rgba(0, 0, 0, .2)',
          height: '40px',
          width: '100%',
          margin: '10px 0',
          padding: '3px 0',
          fontSize: '16px',
          fontWeight: 'bold',
          // fontFamily: 'Helvetica, Arial, sans-serif',
          // textDecoration: 'underline',
          backgroundColor: '#ff7700',
          // borderRadius: '20px'
        }
      },

      onMustAgree: (agreementData) => {
        this.showSpinner = false;
        this.contractAgreementRequired = true;
        this.agreementVerified = true;
        this.agreementUpdateRequired = true;
        this.db.updateContract(artist.id, {
          clickId: agreementData.clickwrapId,
          agreementId: null,
        });
      },
      onAgreed: (agreementData) => {
        this.showSpinner = false;
        this.contractAgreementRequired = false;
        this.agreementVerified = true;
        this.db.updateContract(artist.id, {
          clickId: agreementData.clickwrapId,
          agreementId: agreementData.agreementId,
        });
      }
    }, '#ds-clickwrap');
  }

  ngOnDestroy() {
    this.authSubscription?.unsubscribe();
    this.artistSubscription?.unsubscribe();
  }

  listenToEvents() {
    this.authSubscription = this.auth.user$.subscribe((user) => {
      const loggedIn = !!user;
      if (!loggedIn && this.loggedIn) {
        this.router.navigate(['/login']);
      }
      if (!loggedIn) {
        this.showSpinner = false;
        this.contractAgreementRequired = false;

        return;
      }
      this.loggedIn = loggedIn;
      this.showSpinner = true;

      this.artistSubscription?.unsubscribe();
      this.artistSubscription = this.artistService.signedInArtist$.subscribe((artist) => {
        const hasContractAgreement = !!artist.contract && artist.contract.clickId;
        if (hasContractAgreement) {
          if (!this.agreementVerified) {
            this.displayContractIfNeeded(artist, user);
            this.contractAgreementRequired = !!artist.contract && artist.contract.clickId && !artist.contract.agreementId;
            if (!this.contractAgreementRequired) {
              this.showSpinner = false;
            }
          }
        } else {
          this.contractAgreementRequired = false;
          this.showSpinner = false;
        }
      });
    });
  }

  signOut() {
    this.auth.signOut();
  }

}
