import { Injectable } from '@angular/core';
import {
  collection, collectionData, CollectionReference, collectionSnapshots, deleteDoc, doc, docData,
  documentId, DocumentReference, DocumentSnapshot, Firestore, getDoc,
  limit, orderBy, query, addDoc, Timestamp, updateDoc, where, arrayUnion
} from '@angular/fire/firestore';
import { runTransaction } from '@firebase/firestore';
import { combineLatest, Observable, of, } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { Artist, Contract, Invoice, InvoiceForSearch, InvoiceStatus, SignupForm, Statement } from '../../models/db-models';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  public readonly queryInLimit = 10;

  constructor(
    private firestore: Firestore,
  ) {
  }

  getArtist(artistId: string): Observable<Artist> {
    const docRef = doc(this.firestore, `artists/${artistId}`) as DocumentReference<Artist>;
    return docData(docRef, { idField: 'id' });
  }

  getArtists(artistIds: string[]): Observable<Artist[]> {
    const batches = this.getIdsInBatchesArray(artistIds);

    const observables: Observable<Artist[]>[] = [];
    for (const ids of batches) {
      const observable = collectionData(
        query(
          collection(this.firestore, 'artists') as CollectionReference<Artist>,
          where(documentId(), 'in', ids)
        ), { idField: 'id' });
      observables.push(observable);
    }

    return combineLatest(observables).pipe(map(arr => arr.flat()));
  }

  getArtistsByParentId(parentId: string): Observable<Artist[]> {
    return collectionData(
      query(
        collection(this.firestore, 'artists') as CollectionReference<Artist>,
        where('parentId', '==', parentId)
      ), { idField: 'id' });
  }

  getInvoice(invoiceId: string): Observable<Invoice> {
    const docRef = doc(this.firestore, `invoices/${invoiceId}`) as DocumentReference<Invoice>;
    return docData(docRef);
  }

  getInvoiceOnce(invoiceId: string): Promise<DocumentSnapshot<Invoice>> {
    const docRef = doc(this.firestore, `invoices/${invoiceId}`) as DocumentReference<Invoice>;
    return getDoc(docRef);
  }

  createInvoice(invoice: Invoice | InvoiceForSearch): Promise<DocumentReference<Invoice | InvoiceForSearch>> {
    const collectionRef = collection(this.firestore, 'invoices') as CollectionReference<Invoice | InvoiceForSearch>;
    return addDoc(collectionRef, invoice);
  }

  async setStatusToPaid(statementId: string, invoice: Invoice) {
    const collectionRef = collection(this.firestore, 'invoices') as CollectionReference<Invoice>;
    const createdInvoice = await addDoc(collectionRef, invoice);
    const statementDocRef = doc(this.firestore, `statements/${statementId}`) as DocumentReference<Statement>;
    return updateDoc(statementDocRef, {
      invoiceId: createdInvoice.id,
      status: InvoiceStatus.PAID
    });
  }

  async setStatusToOpen(statement: Statement) {
    const statementDocRef = doc(this.firestore, `statements/${statement.id}`) as DocumentReference<Statement>;
    const statements = await this.getStatementsForInvoiceId(statement.invoiceId).pipe(take(1)).toPromise();
    if (!statements || statements.length === 1) {
      this.deleteInvoice(statement.invoiceId);
    }
    return updateDoc(statementDocRef, {
      invoiceId: null,
      status: InvoiceStatus.OPEN
    });
  }

  async deleteInvoice(invoiceId: string): Promise<void> {
    // set associated statements with invoiceId = null
    const docRef = doc(this.firestore, `invoices/${invoiceId}`) as DocumentReference<Invoice>;
    await deleteDoc(docRef);

    const statements = await this.getStatementsForInvoiceId(invoiceId).pipe(take(1)).toPromise();
    await runTransaction(this.firestore, async (transaction) => {
      statements?.forEach(async statement => {
        const statementRef = doc(this.firestore, `statements/${statement.id}`);
        await transaction.update(statementRef, {
          invoiceId: null,
          status: InvoiceStatus.OPEN
        });
      });
    });

  }

  findArtistIdsThatLabelCanSee(artistId: string, revenueProperty: string): Observable<string[]> {
    return collectionSnapshots(
      query(
        collection(this.firestore, 'artists') as CollectionReference<Artist>,
        where('parentId', '==', artistId),
        where(revenueProperty, '==', true)
      )
    ).pipe(
      map(artists => artists.map(artist => artist.id)),
      tap(artistIds => artistIds.unshift(artistId))
    );
  }

  getStatementsForArtistIds(artistIds: string[], year: number): Observable<Statement[]> {
    const startDate = Timestamp.fromDate(new Date(`${year}-01-01`));
    const endDate = Timestamp.fromDate(new Date(`${year + 1}-01-01`));
    const batches = this.getIdsInBatchesArray(artistIds);

    const observables: Observable<Statement[]>[] = [];
    for (const ids of batches) {
      const observable = collectionData(
            query(
              collection(this.firestore, 'statements') as CollectionReference<Statement>,
              where('artistId', 'in', ids),
              where('date', '>=', startDate),
              where('date', '<', endDate),
              orderBy('date', 'desc')
            ), { idField: 'id' });
      observables.push(observable);
    }

    return combineLatest(observables).pipe(map(arr => arr.flat()));
  }

  getStatementsForArtistIdsLimit(artistIds: string[], year: number): Observable<Statement[]> {
    const startDate = Timestamp.fromDate(new Date(`${year}-01-01`));
    const endDate = Timestamp.fromDate(new Date(`${year + 1}-01-01`));
    const batches = this.getIdsInBatchesArray(artistIds);

    const observables: Observable<Statement[]>[] = [];
    for (const ids of batches) {
      const observable = collectionData(
            query(
              collection(this.firestore, 'statements') as CollectionReference<Statement>,
              where('artistId', 'in', ids),
              where('date', '>=', startDate),
              where('date', '<', endDate),
              where('visible', '==', true),
              // limit(1),
              orderBy('date', 'desc')
            ), { idField: 'id' });
      observables.push(observable);
    }

    return combineLatest(observables).pipe(map(arr => arr.flat()));
  }

  getStatementsForInvoiceId(invoiceId: string): Observable<Statement[]> {
    return collectionData(
      query(
        collection(this.firestore, 'statements') as CollectionReference<Statement>,
        where('invoiceId', '==', invoiceId),
      ), { idField: 'id' }
    );
  }

  getInvoices(invoiceIds: string[]): Observable<Invoice[]> {
    const batches = this.getIdsInBatchesArray(invoiceIds);
    if (!batches || !batches.length) { return of([]); }

    const observables: Observable<Invoice[]>[] = [];
    for (const ids of batches) {
      const observable = collectionData(
            query(
              collection(this.firestore, 'invoices') as CollectionReference<Invoice>,
              where(documentId(), 'in', ids)
            ), { idField: 'id' });
      observables.push(observable);
    }

    return combineLatest(observables).pipe(map(arr => arr.flat()));
  }

  getInvoicesForArtistIds(artistIds: string[]): Observable<Invoice[]> {
    const batches = this.getIdsInBatchesArray(artistIds);
    if (!batches || !batches.length) { return of([]); }

    const observables: Observable<Invoice[]>[] = [];
    for (const ids of batches) {
      const observable = collectionData(
            query(
              collection(this.firestore, 'invoices') as CollectionReference<Invoice>,
              where('artistId', 'in', ids),
              orderBy('date', 'desc')
            ), { idField: 'id' });
      observables.push(observable);
    }

    return combineLatest(observables).pipe(map(arr => arr.flat()));
  }

  getAllInvoicesByStatus(invoiceStatus: InvoiceStatus): Observable<Invoice[]> {
    return collectionData(
      query(
        collection(this.firestore, 'invoices') as CollectionReference<Invoice>,
        where('status', '==', invoiceStatus),
        orderBy('date', 'desc')
      ), { idField: 'id' }
    );
  }

  updateSignInTime(userId: string): Promise<void> {
    const docRef = doc(this.firestore, `artists/${userId}`) as DocumentReference<Artist>;
    return updateDoc(docRef, {
      lastSignIn: Timestamp.now()
    });
  }

  updatePushToken(userId: string, pushToken: string): Promise<void> {
    const docRef = doc(this.firestore, `artists/${userId}`) as DocumentReference<Artist>;
    return updateDoc(docRef, {
      pushTokens: arrayUnion(pushToken)
    });
  }

  updateContract(userId: string, contract: Contract): Promise<void> {
    const docRef = doc(this.firestore, `artists/${userId}`) as DocumentReference<Artist>;
    return updateDoc(docRef, {
      contract: { ...contract }
    });
  }

  getUserRoles(email: string): Observable<Record<string, boolean>> {
    const docRef = doc(this.firestore, `userRoles/${email}`) as DocumentReference<Record<string, boolean>>;
    return docData(docRef);
  }

  async updateInvoiceStatus(id: string, newInvoice: Invoice) {
    const docRef = doc(this.firestore, `invoices/${id}`) as DocumentReference<Invoice>;
    await updateDoc(docRef, { ...newInvoice });
  }

  createSignupForm(signupForm: SignupForm): Promise<DocumentReference<SignupForm>> {
    const collectionRef = collection(this.firestore, 'signupForms') as CollectionReference<SignupForm>;
    return addDoc(collectionRef, signupForm);
  }

  getIdsInBatchesArray(ids: string[]) {
    const batches = [];
    const idsCopy = ids.slice(0);
    while (idsCopy?.length) {
      batches.push(idsCopy.splice(0, this.queryInLimit));
    }
    return batches;
  }

}
