import { NgModule } from '@angular/core';
import { AuthGuard, canActivate, hasCustomClaim, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs.page';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const adminOnly = () => hasCustomClaim('manager');

const routes: Routes = [
  {
    path: 'pages',
    component: TabsPage,
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: 'home',
        loadChildren: () => import('../../pages/content/content.module').then( m => m.ContentPageModule)
      },
      {
        path: 'home/new-password',
        loadChildren: () => import('../../pages/login/new-password/new-password.module').then(m => m.NewPasswordModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('../tab1/tab1.module').then(m => m.Tab1PageModule)
      },
      {
        path: 'profile/user-data',
        loadChildren: () => import('../../pages/withdraw/withdraw-review/withdraw-review.module').then(m => m.WithdrawReviewPageModule)
      },
      {
        path: 'profile/new-password',
        loadChildren: () => import('../../pages/login/new-password/new-password.module').then(m => m.NewPasswordModule)
      },
      {
        path: 'profile/payment-history',
        loadChildren: () => import('../../pages/payment-history/payment-history.module').then(m => m.PaymentHistoryPageModule)
      },
      {
        path: 'earnings',
        loadChildren: () => import('../tab2/tab2.module').then(m => m.Tab2PageModule)
      },
      {
        path: 'earnings/payment-history',
        loadChildren: () => import('../../pages/payment-history/payment-history.module').then(m => m.PaymentHistoryPageModule)
      },
      {
        path: 'payment-history',
        loadChildren: () => import('../../pages/payment-history/payment-history.module').then(m => m.PaymentHistoryPageModule)
      },
      {
        path: 'withdraw',
        loadChildren: () => import('../../pages/withdraw/withdraw.module').then(m => m.WithdrawPageModule)
      },
      {
        path: 'statement',
        loadChildren: () => import('../statement/statement.module').then(m => m.StatementPageModule)
      },
      {
        path: '',
        redirectTo: 'earnings',
        pathMatch: 'full'
      },
      {
        path: 'support',
        loadChildren: () => import('../../pages/tab3/tab3.module').then(m => m.Tab3PageModule)
      },
      {
        path: 'admin',
        ...canActivate(adminOnly),
        loadChildren: () => import('../../pages/admin/admin.module').then(m => m.AdminPageModule)
      }
    ]
  },
  {
    path: '',
    redirectTo: '/pages/earnings',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class TabsPageRoutingModule { }
