import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Artist, InvoiceStatus } from '../../models/db-models';
import { AuthService } from '../auth/auth.service';
import { DbService } from './db.service';
import { NotificationService } from './notification.service';
import { StorageKeys, StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ArtistService {

  private _selectedArtistId$: Subject<string> = new ReplaySubject(1);
  private _signedInArtist$: Subject<Artist> = new ReplaySubject(1);
  private _selectedPartnerId$: Subject<string> = new ReplaySubject(1);
  private _artistIdsLabelSeeRevenue$: Subject<string[]> = new ReplaySubject(1);
  private _artistIdsPaymentByLabel$: Subject<string[]> = new ReplaySubject(1);
  private artistSubscription: Subscription;

  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private db: DbService,
    private notificationService: NotificationService
  ) {
    this.init();
  }

  get selectedArtistId$(): Observable<string> {
    return this._selectedArtistId$;
  }

  get signedInArtist$(): Observable<Artist> {
    return this._signedInArtist$;
  }

  get artistIdsLabelSeeRevenue$(): Observable<string[]> {
    return this._artistIdsLabelSeeRevenue$;
  }

  get artistIdsPaymentByLabel$(): Observable<string[]> {
    return this._artistIdsPaymentByLabel$;
  }

  get selectedPartnerId$(): Observable<string> {
    return this._selectedPartnerId$;
  }

  async init() {
    this.auth.user$.subscribe((signedInUser) => {
      this.auth.customClaims$.subscribe(async (customClaims) => {
        let userId = signedInUser?.uid;
        if (customClaims?.manager) {
          userId = await this.storage.get(StorageKeys.selectedPartnerId);
        } else {
          this.auth.updateSignedInTime(userId);
          this.notificationService.requestPermission(userId);
        }
        this.fetchArtist(userId, customClaims?.manager);
      });
    });
  }

  async fetchArtist(userId: string, isAdmin: boolean) {
    if (userId) {
      this.artistSubscription?.unsubscribe();
      this.artistSubscription = this.db.getArtist(userId).subscribe(async (artist) => {
        if (artist) {
          this._signedInArtist$.next(artist);
        }
      });

      this.getArtistIdsForSignedInUser(userId);
      const storageKey = isAdmin ? StorageKeys.selectedPartnerId : StorageKeys.selectedArtistId;
      const selectedArtistId = await this.storage.get(storageKey);

      if (!selectedArtistId) {
        this.selectArtistId(userId);
      } else {
        this.selectArtistId(selectedArtistId);
      }
    } else {
      this._signedInArtist$.next(null);
    }
  }

  getArtistIdsForSignedInUser(signedInUserId: string): void {
    this.db.findArtistIdsThatLabelCanSee(signedInUserId, 'labelSeeRevenue').subscribe((artistIds) => {
      this._artistIdsLabelSeeRevenue$.next(artistIds);
    });
    this.db.findArtistIdsThatLabelCanSee(signedInUserId, 'paymentByLabel').subscribe((artistIds) => {
      this._artistIdsPaymentByLabel$.next(artistIds);
    });
  }

  async selectArtistId(id: string) {
    this._selectedArtistId$.next(id);
    await this.storage.set(StorageKeys.selectedArtistId, id);
  }

  async selectPartnerId(id: string) {
    await this.storage.set(StorageKeys.selectedPartnerId, id);
    this.fetchArtist(id, true);
  }

  getName(artist: Artist): string {
    let name = artist?.name;
    if (artist?.labelName) {
      name = `[${artist.labelName}] ${name}`;
    }
    return name;
  }

  getColorByStatus(invoiceStatus: string) {
    switch (invoiceStatus) {
      case InvoiceStatus.OPEN:
        return 'success';
      case InvoiceStatus.ADVANCE:
        return 'medium';
      case InvoiceStatus.REQUESTED:
      case InvoiceStatus.SCHEDULED:
        return 'pending';
      case InvoiceStatus.PAID:
        return 'danger';
    }
    return 'primary';
  }

  getTranslatedStatus(invoiceStatus: string, total: number = 0) {
    switch (invoiceStatus) {
      case InvoiceStatus.OPEN:
        return 'disponível';
      case InvoiceStatus.REQUESTED:
      case InvoiceStatus.SCHEDULED:
        return 'em andamento';
      case InvoiceStatus.PAID:
        return 'pago';
      case InvoiceStatus.ADVANCE:
        return total >= 0 ? 'adiantamento' : 'crédito';
    }
    return 'indisponível';
  }

  getTranslatedStatusAdmin(invoiceStatus: string) {
    switch (invoiceStatus) {
      case InvoiceStatus.OPEN:
        return 'Em aberto';
      case InvoiceStatus.REQUESTED:
        return 'Solicitado';
      case InvoiceStatus.SCHEDULED:
        return 'Agendado';
      case InvoiceStatus.PAID:
        return 'Pago';
      case InvoiceStatus.ADVANCE:
        return 'ADIANTAMENTO';
    }
    return 'indisponível';
  }

}
