import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArtistService } from '../../../services/artist.service';
import algoliasearch from 'algoliasearch';
import { AuthService } from '../../../auth/auth.service';
import { environment } from '../../../../environments/environment';
import { InstantSearchConfig } from 'angular-instantsearch/instantsearch/instantsearch';
import { AdvanceType } from '../../../../models/db-models';

@Component({
  selector: 'app-partners-list',
  templateUrl: './partners-list.component.html',
  styleUrls: ['./partners-list.component.scss'],
})
export class PartnersListComponent implements OnInit, OnDestroy {
  @Input() advanceType: AdvanceType;
  config$: Observable<InstantSearchConfig>;
  advanceValues: number[] = [];
  headerColor = '';

  constructor(
    public modalController: ModalController,
    private auth: AuthService,
    public artistService: ArtistService,
  ) { }

  ngOnInit() {
    this.initConfig();
    switch (this.advanceType) {
      case 'advance':
        this.headerColor = 'danger';
        break;
      case 'credit':
        this.headerColor = 'success';
        break;
    }
  }

  ngOnDestroy(): void {
  }

  initConfig() {
    this.config$ = this.auth.searchKey$.pipe(
      map(searchKey => {
        const searchClient = algoliasearch(
          environment.search.appId,
          searchKey
        );
        return {
          indexName: environment.search.artistIndexName,
          searchClient
        };
      })
    );
  }

  dismiss(artistId?: string) {
    this.modalController.dismiss(artistId);
  }
}
