// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'bmartists-dev',
    appId: '1:608950171177:web:c0baf1692367e838afce38',
    storageBucket: 'bmartists-dev.appspot.com',
    locationId: 'southamerica-east1',
    apiKey: 'AIzaSyA459xu_b4sPH6bmScdWvu1kqgoen7kSIo',
    authDomain: 'bmartists-dev.firebaseapp.com',
    messagingSenderId: '608950171177',
    measurementId: 'G-T2PK69RVEB',
  },
  config: {
    minimumWithdrawValue: 100,
    supportEmail: 'suporte@bergermobile.com.br',
    faqUrl: 'https://bergermob.com/faq/',
    privacyTermsBaseUrl: 'https://bergermobile.com.br/bergerforartists/',
    whatsAppUrl: 'https://wa.me/5511991181551?text=Gostaria%20de%20suporte%20com%20o%20Berger%20para%20Artistas'
  },
  search: {
    appId: 'H5LP40WKVA',
    artistIndexName: 'dev_artists',
    invoiceIndexName: 'dev_invoices'
  },
  docusign: {
    environment: 'https://demo.docusign.net',
    accountId: 'd442fcea-aded-4f16-9a6e-bc2440f0131a',
  },
  externalSystem: {
    authorizeUrl: 'https://staging.revelator.pro/authorize',
    authorizeUrlV1: 'https://staging.revelator.com/Home/IndexWithToken',
  },
  defaultRegion: 'southamerica-east1',
  production: false,
  useEmulators: false,
  useFunctionsEmulator: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
