import { Injectable, Optional } from '@angular/core';
import { getApp } from '@angular/fire/app';
import {
  Auth, authState, EmailAuthProvider, getAuth, reauthenticateWithCredential, sendPasswordResetEmail,
  signInWithEmailAndPassword, signOut, updatePassword, User, UserCredential
} from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/performance';
import { EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { DbService } from '../services/db.service';
import { StorageService } from '../services/storage.service';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from '@angular/fire/functions';
import { environment } from '../../environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { LoginResult } from 'src/models/external-system.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loggedIn$: Subject<boolean> = new Subject();
  public customClaims$: Observable<Record<string, boolean>> = new Subject();
  public user$: Observable<User | null> = EMPTY;
  public searchKey$: ReplaySubject<string> = new ReplaySubject(1);
  public externalAccess$: ReplaySubject<LoginResult> = new ReplaySubject(1);
  private keyInitialized = false;
  private externalAccessInitialized = false;

  constructor(
    @Optional() private auth: Auth,
    private db: DbService,
    private storage: StorageService,
  ) {
    this.observeAuthState();
  }

  public observeAuthState() {
    if (this.auth) {
      this.user$ = authState(this.auth);
      authState(this.auth).pipe(
        tap(user => {
          if (user?.uid) {
            this.initExternalAccess();
            this.setClaims(user.email);
          }
        }),
        traceUntilFirst('auth'),
        map(u => !!u)
      ).subscribe(isLoggedIn => {
        SplashScreen.hide();
        this.loggedIn$.next(isLoggedIn);
      });
    }
  }

  async signIn(email: string, password: string): Promise<UserCredential> {
    await this.storage.clear();
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  async setClaims(email: string) {
    this.customClaims$ = this.db.getUserRoles(email).pipe(
      tap((data) => {
        this.auth.currentUser?.getIdTokenResult();
        if (data?.manager) {
          this.initSearchKey();
        }
      }),
    );
  }

  async initSearchKey() {
    if (!this.keyInitialized) {
      this.keyInitialized = true;
      const functions = getFunctions(getApp(), environment.defaultRegion);
      if (environment.useFunctionsEmulator) {
        connectFunctionsEmulator(functions, 'localhost', 6001);
      }
      const searchKeyFunction = httpsCallable(functions, 'getSecuredSearchKeyV2');
      const searchKeyResult = await searchKeyFunction() as any;
      this.searchKey$.next(searchKeyResult.data.searchKey);
    }
  }

  async initExternalAccess() {
    if (!this.externalAccessInitialized) {
      this.externalAccessInitialized = true;
      const functions = getFunctions(getApp(), environment.defaultRegion);
      if (environment.useFunctionsEmulator) {
        connectFunctionsEmulator(functions, 'localhost', 6001);
      }
      const externalLoginFunction = httpsCallable(functions, 'externalLogin');
      const externalLoginResult = await externalLoginFunction() as any;
      const externalLoginResultData = externalLoginResult.data as LoginResult;
      // console.log('>>> got externalLoginResult', externalLoginResult);
      this.externalAccess$.next(externalLoginResultData);
    }
  }

  async updateSignedInTime(userId: string) {
    this.db.updateSignInTime(userId);
  }

  public async signOut(): Promise<any> {
    const auth = getAuth();
    signOut(auth);
    this.storage.clear();
  }

  public forgotPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(getAuth(), email);
  }

  async updatePassword(newPassword: string): Promise<void> {
    const authenticatedUser = await this.user$
    .pipe(take(1)).toPromise();
    return updatePassword(authenticatedUser, newPassword);
  }

  async reauthenticateUser(currentPassword: string): Promise<UserCredential> {
    const authenticatedUser = await this.user$
    .pipe(take(1)).toPromise();

    const credential = EmailAuthProvider.credential(authenticatedUser.email, currentPassword);

    return reauthenticateWithCredential(authenticatedUser, credential);
  }

  public getTranslatedErrorMessage(errorCode: string) {
    switch (errorCode) {
      case 'auth/invalid-email':
      case 'auth/user-not-found':
        return 'E-mail não encontrado';
      case 'auth/invalid-password':
      case 'auth/wrong-password':
        return 'Senha incorreta';
      default:
        return 'Erro de autenticação';
    }
  }

}

