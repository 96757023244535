import { DocumentSnapshot, Timestamp } from "@angular/fire/firestore";
import { Observable } from "rxjs";

export interface Artist {
  id?: string;
  objectID?: string;
  internalId?: string;
  type?: ArtistType;
  paymentByLabel?: boolean;
  labelSeeRevenue?: boolean;
  share?: number;
  name?: string;
  labelName?: string;
  notes?: string;
  taxId?: string;
  invitedAt?: Timestamp;
  lastSignIn?: Timestamp;
  pushTokens?: string[];
  parentId?: string;
  paymentInfo?: PaymentInfo;
  address?: Address;
  lastInvoiceStatus?: InvoiceStatus;
  totalStatementSum?: number;
  totalStatementsSumIndividual?: number;
  contract?: Contract;
  
  _highlightResult?: {
    name: {
      value: string;
      matchLevel: string;
      fullyHighlighted: boolean;
      matchedWords: string[];
    },
    labelName: {
      value: string;
      matchLevel: string;
      fullyHighlighted: boolean;
      matchedWords: string[];
    }
  }
}

export interface ArtistWithChildren extends Artist {
  children: Observable<Artist[]>;
  doc: DocumentSnapshot<Artist>
}

export interface ArtistWithSum {
  artist: Artist;
  sum: number;
}

export interface PaymentInfo {
  bankNumber: number;
  bankName: string;
  agency: string;
  account: string;
  iban: string;
  swift: string;
  type: PaymentType;
  typeKey: string;
  typeValue: string;
  notes: string;
}

export interface Address {
  zip: string;
  address1: string;
  address2: string;
  region: string;
  city: string;
  state: string;
}

export interface Statement {
  id: string;
  date: Timestamp;
  internalId: string;
  name: string;
  total: number;
  currency: string;
  invoiceId: string;
  artistId: string;
  statementFiles: StatementFile[];
  status: InvoiceStatus;
  visible?: boolean;
}

export interface StatementFile {
  filename: string;
  type: string;
}

export interface Invoice {
  id?: string;
  date?: Timestamp;
  scheduledDate?: Timestamp;
  paidDate?: Timestamp;
  total?: number;
  name?: string;
  status?: InvoiceStatus;
  visible?: boolean;
  artistId?: string;
  artistName?: string;
  labelName?: string;
  advanceDescription?: string;
}

export type AdvanceType = 'advance' | 'credit' | undefined;

export interface InvoiceForSearch {
  objectID?: string;
  scheduledDate?: number;
  paidDate?: number;
  date?: number;
  total?: number;
  name?: string;
  status?: InvoiceStatus;
  visible?: boolean;
  artistId?: string;
  artistName?: string;
  labelName?: string;
  advanceDescription?: string;
  _highlightResult?: any;
}

export interface ReportLines {
  trackName: string;
  artistName: string;
  writers: string;
  paid: number;
  sharePercentage: number;
  productType: string;
  grossIncome: number;
  quantity: number;
  upc: string;
  isrc: string;
  dsp: string;
  customId: string;
  country: string;
  currency: string;
  artistId: string;
  statementId: string;
}

export interface SignupForm {
  name: string;
  artistName: string;
  email: string;
  phone: string;
  labelName: string;
  dspLinks: string;
  socialMediaLinks: string;
  city: string;
  about: string;
}

export interface Contract {
  clickId: string;
  agreementId: string;
}

export enum ArtistType {
  LABEL = "LABEL",
  ARTIST = "ARTIST"
}

export enum PaymentType {
  TED = "TED",
  PIX = "PIX",
  OTHER = "OTHER"
}

export enum InvoiceStatus {
  REQUESTED = "REQUESTED",
  SCHEDULED = "SCHEDULED",
  PAID = "PAID",
  OPEN = "OPEN",
  ADVANCE = "ADVANCE"
}

export enum StatementFileType {
  EXCEL = "EXCEL",
  CSV = "CSV",
  PDF = "PDF",
  HTML = "HTML",
  ZIP = "ZIP"
}

// export enum ContractStatus {
//   SIGNED = "SIGNED",
//   PENDING = "PENDING",
//   NOT_SIGNED = "NOT_SIGNED"
// }
