import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Artist, InvoiceStatus } from '../../../models/db-models';
import { ArtistService } from '../../services/artist.service';
import { DbService } from '../../services/db.service';

@Component({
  selector: 'app-artist-card',
  templateUrl: './artist-card.component.html',
  styleUrls: ['./artist-card.component.scss'],
})
export class ArtistCardComponent implements OnInit, OnDestroy {

  @Input() selected: boolean;
  @Input() artist: Artist;
  @Input() self: boolean;
  @Input() version = 'full';
  @Input() showChangeArtist = false;
  @Output() changeClick = new EventEmitter();
  artistStatus: InvoiceStatus = null;

  constructor(
    private db: DbService,
    public artistService: ArtistService,
  ) { }

  ngOnInit() {
  }
  ngOnDestroy(): void {
  }

  onChangeClick() {
    this.changeClick.emit();
  }

}
