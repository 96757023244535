import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { ArtistService } from '../../../services/artist.service';

@Component({
  selector: 'app-select-partner',
  templateUrl: './select-partner.component.html',
  styleUrls: ['./select-partner.component.scss'],
})
export class SelectPartnerComponent implements OnInit {

  constructor(
    public artistService: ArtistService,
    public auth: AuthService
  ) { }

  ngOnInit() {}

}
