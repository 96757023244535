import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { ArtistService } from '../../services/artist.service';
import { adminRoutes } from '../admin/admin-routing.module';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements OnInit, OnDestroy {

  routesToShowTabs = [
    '/',
    '/pages/home',
    '/pages/profile',
    '/pages/profile/user-data',
    '/pages/profile/new-password',
    '/pages/earnings',
    '/pages/support',
    '/pages/admin',
    '/pages/admin/invoices',
  ];
  routerSubscription: Subscription;
  showTabs = true;
  hideSelectPartner = false;

  constructor(
    public auth: AuthService,
    public artistService: ArtistService,
    private router: Router
  ) {}

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showTabs = this.routesToShowTabs.includes(event.url);
        this.hideSelectPartner = adminRoutes.includes(event.url);
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
  }

}
