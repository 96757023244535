import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';

import { Storage } from '@ionic/storage-angular';

export enum StorageKeys {
  selectedArtistId = 'SELECTED_ARTIST_ID',
  selectedPartnerId = 'SELECTED_PARTNER_ID',
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    if (!this._storage) {
      const storage = await this.storage.create();
      this._storage = storage;
    }
  }

  public async set(key: string, value: any): Promise<void> {
    if (!this._storage) {
      await this.init();
    }
    this._storage?.set(key, value);
  }

  public async get(key: string): Promise<any> {
    if (!this._storage) {
      await this.init();
    }
    return this._storage?.get(key);
  }

  public async clear(): Promise<void> {
    return this._storage?.clear();
  }

  public downloadBlob(blob: Blob, filename: string) {
    if (Capacitor.isNativePlatform()) {
      this.downloadToDevice(blob, filename);
    } else {
      this.downloadToBrowser(blob, filename);
    }
  }

  private downloadToBrowser(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  private downloadToDevice(blob: Blob, filename: string) {
    let dataForDownload: any;
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async () => {
      dataForDownload = reader.result;

      try {
        const directory =
          Capacitor.getPlatform() === 'ios'
              ? Directory.Documents
              : Directory.ExternalStorage;
        await Filesystem.requestPermissions();
        await Filesystem.writeFile({
          path: `Download/${filename}`,
          data: dataForDownload,
          directory,
          // encoding: Encoding.UTF8,
          recursive: true,
        });
        const finalFileUri = await Filesystem.getUri({
          directory,
          path: `Download/${filename}`
        });

        if (Capacitor.getPlatform() === 'ios') {
          await Share.share({
            title: filename,
            url: finalFileUri.uri
          });
        }
      } catch (e) {
        console.error('Error downloading file', e);
      }
    };
  }
}
